$sizes: 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23, 24, 25, 26, 27, 28, 29, 30, 31, 32, 33, 34, 35, 36, 37, 38, 39, 40, 41, 42, 43, 44, 45, 46, 47, 48, 49, 50, 51, 52, 53, 54, 55, 56, 57, 58, 59, 60, 61, 62, 63, 64, 65, 66, 67, 68, 69, 70, 71, 72, 73, 74, 75, 76, 77, 78, 79, 80, 81, 82, 83, 84, 85, 86, 87, 88, 89, 90, 91, 92, 93, 94, 95, 96, 97, 98, 99, 100, 101, 102, 103, 104, 105, 106, 107, 108, 109, 110, 111, 112, 113, 114, 115, 116, 117, 118, 119, 120, 121, 122, 123, 124, 125, 126, 127, 128, 129, 130, 131, 132, 133, 134, 135, 136, 137, 138, 139, 140, 141, 142, 143, 144, 145, 146, 147, 148, 149, 150, 151, 152, 153, 154, 155, 156, 157, 158, 159, 160, 161, 162, 163, 164, 165, 166, 167, 168, 169, 170, 171, 172, 173, 174, 175, 176, 177, 178, 179, 180, 181, 182, 183, 184, 185, 186, 187, 188, 189, 190, 191, 192, 193, 194, 195, 196, 197, 198, 199, 200;

$defaultfontfamily: 'DM Sans', sans-serif;
$secondaryfontfamily: 'DM Sans', sans-serif;
$white: #ffffff;
$black: #000000;
$primary: #5A3CAE;
$primaryrgba: 90, 60, 174;
$primary100: #9B63FF;
$primary200: #8255D9;

$secondary: #C6B1EA;
$secondary100: #DDCAFF;
$secondary200: #DCC9FD;
$secondary300: #A781CA;
$secondary400: #F1EAFF;

$dark: #160546;
$dark100: #281F40;
$dark200: #031B3E;

$purpleTaupe: #534D63;

$lightGreen: #A2F4E3;
$lightGreen100: #ACEDDF;
$lightGreen200: #D0FAF1;
$lightGreen300: #43CBAE;
$lightGreen400: #21A0A8;

$green: #63D43B;
$orange: #ED752E;

$gray: #808080;
$gray100: #B0B0B0;
$gray200: #E7E7E7;
$gray300: #A5A5A5;
$gray400: #B1B1B2;
$gray500: #747474;
$gray600: #D4D4D4;
$gray700: #D0D5DD;
$gray800: #DDDDDD;

$light: #F5F5F5;
$light100: #F2F2F4;
$light200: #F6F7F8;
$success: #13B693;
$warning: #DBAF13;
$danger: #E65E5E;
$danger100: #EC2929;

@each $size in $sizes {
  .mt-#{$size} {
    margin-top: $size * 1px;
  }
  .mb-#{$size} {
    margin-bottom: $size * 1px;
  }
  .mr-#{$size} {
    margin-right: $size * 1px;
  }
  .ml-#{$size} {
    margin-left: $size * 1px;
  }
}