.qr-code-wrapper{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    position: relative;
    .qr-code-refresh{
        display: flex;
        align-items: center;
        justify-content: center;
        position: absolute;
        left: 0;
        right: 0;
        top: 0;
        bottom: 0;
        backdrop-filter: blur(3.5px);
    }
}