.dp-wrapper {
    display: flex;
    align-items: center;
    position: relative;
    height: 100%;
    .dp-heading {
      display: flex;
      align-items: center;
      cursor: pointer;
      color: $black;
      border: none;
      background-color: transparent;
      min-height: 4rem;
      .dp-heading-avatar {
        margin-right: 0.75rem;
      }
      .dp-heading-text {
        margin-right: 0.75rem;
        font-size: 0.875rem;
        font-weight: 500;
        color: $light;
      }
    }
    .overlay-backdrop {
      position: fixed;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      z-index: 1;
    }
    .dp-content {
      display: flex;
      flex-direction: column;
      background-color: $black;
      box-shadow: 0px 0px 8px rgba(255, 255, 255, 0.2);
      border-radius: 8px;
      width: 100%;
      position: absolute;
      top: 100%;
      min-width: 15rem;
      z-index: 99;
      right: 0;
      .dp-content-list {
        display: flex;
        flex-direction: column;
        width: 100%;
        list-style: none;
        padding: 0;
        margin: 0;
        .dp-list-item {
          display: flex;
          flex-direction: column;
          margin-top: 3px;
          &.dp-list-vep-item {
            padding: 1.25rem 2.75rem;
            .dp-list-item-link {
              font-size: 0.875rem;
              padding: 0.5rem 0.875rem;
              font-weight: 500;
              border-radius: 100px;
              background: linear-gradient(
                90deg,
                #fcca14 1.5%,
                #fee878 48.01%,
                #fcc60a 94.52%
              );
              border-radius: 100px;
              color: $black;
              line-height: 1.3125rem;
            }
          }
          .dp-list-item-link {
            display: flex;
            padding: 1rem;
            color: $black;
            font-size: 1.125rem;
            font-weight: 400;
            line-height: 1rem;
            background-color: $white;
            text-align: right !important;
            width: 100%;
            border: $white;
          }
          &:last-child {
            .dp-list-item-link {
              border-radius: 0 0 8px 8px;
            }
          }
        }
      }
    }
  }