.mbb-card{
  display: flex;
  flex-direction: column;
  padding: 2.5rem 1.875rem 1.875rem;
  background-color: $lightGreen200;
  border-radius: 0.5rem;
  height: calc(100% - 1.5rem);
  margin-bottom: 1.5rem;
  .mbb-img-icon{
      display: flex;
      flex-direction: column;
      margin-bottom: 1.875rem;
      align-items: flex-start;
  }
  .mbb-text{
      font-size: 1rem;
      font-weight: 600;
      color: $dark;
      margin-bottom: 0.75rem;
  }
  .mbb-desc{
      font-size: 0.875rem;
      font-weight: 400;
      color: $dark;
      margin-bottom: 0;        
  }
}
