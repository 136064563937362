.footer-wrapper{
    display: flex;
    flex-direction: column;
    width: 100%;
    background-color: $primary;
    color: $white;
    background-image: url('../../../img/bg-footer.svg');
    background-repeat: no-repeat;
    background-position: left bottom;
    padding-top: 3rem;
    .container{
        max-width: 1068px;
    }
    .copyright-wrapper{
        display: flex;
        flex-direction: column;
        width: 100%;
        border-top: 1px solid rgba($color: $white, $alpha: 0.1);
        padding-top: 1.25rem;
        margin-top: 0.5rem;
        padding-bottom: 5.5rem;
        text-align: left;
        color: $white;
        font-size: 1rem;
        font-weight: 400;
    }
    .ft-title{
        font-weight: 700;
        color: $white;
        margin-bottom: 1rem;
    }
}
@media screen and (max-width: 767px) {
    .footer-wrapper{
        background-size: 50%;
        .container{
            max-width: 100%;
        }
        .social-media-wap{
            margin-bottom: 2rem;
        }
    }
}