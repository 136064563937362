.nav-tab-wrapper{
    display: flex;
    align-items: center;
    list-style: none;
    margin: 0 0 0.8125rem;
    padding: 0;
    &.support-nav-tab{
        margin-bottom: 2.5rem;
        .nav-tab-item{
            padding-left: 0.4375rem;
            padding-right: 0.4375rem;
            .nav-tab-item-link{
                padding: 1.25rem 1.5rem;
                border: 1px solid $secondary400;
                background-color: $secondary400;
                border-radius: 0.5rem;
                &::after{
                    content: none;
                }
                .nav-tab-item-title{
                    color: $dark;
                    font-size: 1.25rem;
                    font-weight: 500;
                }
                .nav-tab-item-icon{
                    background-color: $white;
                    width: 2.75rem;
                    height: 2.75rem;
                    border-radius: 3px;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    margin-right: 1.25rem;
                }
            }
            &.active{
                .nav-tab-item-link{
                    border-color: $primary;
                }
            }
        }
    }
    .nav-tab-item{
        display: flex;
        flex-direction: column;
        padding-left: 0.875rem;
        padding-right: 0.875rem;
        &:last-child{
            margin-right: 0;
        }
        .nav-tab-item-link{
            display: flex;
            align-items: center;
            justify-content: center;
            cursor: pointer;
            padding: 0.5rem;
            position: relative;
            transition: all ease-in 0.4s;
            &::after{
                content: '';
                position: absolute;
                bottom: 0;
                height: 4px;
                width: 0%;
                background-color: transparent;
                border-radius: 0.25rem;
                transition:  width ease-in-out 0.2s;
            }
            .nav-tab-item-title{
                display: flex;
                font-size: 0.875rem;
                font-weight: 700;
                color: $dark100;
                margin-bottom: 0;
            }
        }
        &.active{
            .nav-tab-item-link{
                &::after{
                    background-color: $primary;
                    width: 100%;
                }
                .nav-tab-item-title{
                }
            }
        }
    }
}
@media screen and (max-width: 767px) {
    .nav-tab-wrapper{
        &.support-nav-tab{
            align-items: inherit;
            margin-bottom: 1.5rem;
            .nav-tab-item{
                flex: 1;
                .nav-tab-item-link{
                    padding: 1rem 1rem;
                    display: flex;
                    flex-direction: column;
                    height: 100%;
                    .nav-tab-item-title{
                        font-size: 1rem;
                        font-weight: 500;
                        text-align: center;
                    }
                    .nav-tab-item-icon{
                        margin-right: 0;
                        margin-bottom: 1rem;
                    }
                }
                &.active{
                    .nav-tab-item-link{
                        border-color: $primary;
                    }
                }
            }
        }
        .nav-tab-item{
            display: flex;
            flex-direction: column;
            padding-left: 0.875rem;
            padding-right: 0.875rem;
            &:last-child{
                margin-right: 0;
            }
            .nav-tab-item-link{
                display: flex;
                align-items: center;
                justify-content: center;
                cursor: pointer;
                padding: 0.5rem;
                position: relative;
                transition: all ease-in 0.4s;
                &::after{
                    content: '';
                    position: absolute;
                    bottom: 0;
                    height: 4px;
                    width: 0%;
                    background-color: transparent;
                    border-radius: 0.25rem;
                    transition:  width ease-in-out 0.2s;
                }
                .nav-tab-item-title{
                    display: flex;
                    font-size: 0.875rem;
                    font-weight: 700;
                    color: $dark100;
                    margin-bottom: 0;
                }
            }
            &.active{
                .nav-tab-item-link{
                    &::after{
                        background-color: $primary;
                        width: 100%;
                    }
                    .nav-tab-item-title{
                    }
                }
            }
        }
    }
}