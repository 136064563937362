.progress-bar-wrapper{
    display: flex;
    flex-direction: column;
    width: 100%;
    position: relative;
    background-color: $white;
    border-radius: 0.375rem;
    margin-bottom: 0.375rem;
    // overflow: hidden;
    .progress-percent{
        background-color: $primary;
        height: 0.625rem;
        border-radius: 0.375rem;
    }
}
.price-min-range-max{
    display: flex;
    align-items: center;
    justify-content: space-between;
    font-size: 0.875rem;
    font-weight: 500;
}