.document-upload-modal {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 20px;
  background-color: #fff;
  border-radius: 8px;
  // box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  height: 600px;
  margin: auto;

  h3 {
    margin-top: 20px;
    margin-bottom: 20px;
    font-size: 24px;
    font-weight: 700;
    text-align: center;
  }

  form {
    width: 100%;
    display: flex;
    flex: 1;
    flex-direction: column;
    justify-content: space-between;

    section {
      margin-bottom: 20px;
    }

    .modal__form-drop {
      border: 2px solid gray;
      border-radius: 100px;
      padding: 20px;
      text-align: center;
      transition: border-color 0.3s ease;

      &:hover {
        border-color: #6519a4;
      }

      input {
        display: none;
      }

      h6 {
        margin: 0;
      }
    }

    .modal__form-error {
      color: red;
      margin-top: 10px;
    }

    .modal_form_buttons {
      display: flex;
      flex-direction: row;
      justify-content: space-between;

      button {
        margin-top: 10px;
        margin-bottom: 20px;
        padding: 10px 20px;
        font-size: 16px;
        border: none;
        border-radius: 100px;
        cursor: pointer;
        transition: background-color 0.3s ease;

        &[type="submit"] {
          // background-color: #007bff;
          // color: #fff;

          &:hover {
            background-color: #610c9e;
          }
        }

        &[type="button"] {
          // background-color: #6c757d;
          // color: #fff;

          &:hover {
            background-color: #610c9e;
          }
        }
      }
    }
  }

  .video-upload {
    margin-bottom: 10px;
    text-align: center;
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: space-between;
    flex: 1;

    div{
      display: flex;
      justify-content: space-between;
      margin-top: 10px;
    }

    label {
      display: block;
      margin-bottom: 10px;
      font-weight: 600;
    }

    video {
      margin-top: 9px;
      border-radius: 8px;
      box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
    }
  }
}
