.card-wrapper{
    display: flex;
    flex-direction: column;
    width: 100%;
    background-color: $white;
    border-radius: 2.25rem;
    box-shadow: 8px 8px 24px 0px rgba(0, 0, 0, 0.30);
    padding: 1.875rem 1.125rem;
    .card-main{
        display: flex;
        flex-direction: column;
    }
}
@media screen and (max-width: 767px) {
    .card-wrapper{
        padding: 1.875rem 1.125rem;
    }
}