.form-check-field{
    display: flex;
    flex-direction: column;
    margin-bottom: 1.25rem;
    .form-check{
        display: flex;
        align-items: center;
        &.form-check-readonly{
            pointer-events: none;
            opacity: 0.5;
        }
        &.form-check-checked{
            .form-check-box{
                border-color: $primary;
                display: flex;
            }
        }
        .form-check-box{
            display: flex;
            flex-direction: column;
            margin: 0 0.625rem 0 0;
            padding: 0;
            position: relative;
            min-width: 1.625rem;
            width: 1.625rem;
            height: 1.625rem;
            border: 0px;
            border-radius: 50%;
            background-color: rgba($color: $dark100, $alpha: 0.08);
            .form-check-input{
                position: absolute;
                top: 0;
                bottom: 0;
                left: 0;
                right: 0;
                margin: 0;
                width: 100%;
                height: 100%;
                cursor: pointer;
                opacity: 0;
            }
        }
        .form-check-label{
            display: flex;
            font-weight: 400;
            font-size: 0.8125rem;
            color: $dark;
            cursor: pointer;
            line-height: inherit;
        }
    }
    .message-error{
        color: $danger;
    }
}
