.icon-wrap{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 2.25rem;
    height: 2.25rem;
    border-radius: 50%;
    &.icon-gray{
        background-color: $gray200;
        color: $dark;
    }
    &.icon-white{
        background-color: $white;
        color: $black;
    }
}