.ticket-detail-main-wrapper{
    display: flex;
    width: 100%;
    justify-content: space-between;
    .ticket-detail-left{
        display: flex;
        flex-direction: column;
        width: 17.5rem;
    }
    .ticket-detail-right{
        display: flex;
        flex-direction: column;
        width: calc(100% - 18.5rem);
        background-color: $white;
        border-radius: 0.625rem;
        .ticket-detail-right-title{
            display: flex;
            border-bottom: 1px solid rgba($color: $dark100, $alpha: 0.2);
            padding: 1.5rem 1.875rem 0.875rem;
            h4{
                font-size: 1.25rem;
                font-weight: 500;
                color: $dark;
                margin-bottom: 0;
            }
        }
    }
}
.ticket-detail-side-box{
    display: flex;
    flex-direction: column;
    width: 100%;
    border-radius: 0.625rem;
    background-color: $white;
    .ticket-detail-side-heading{
        display: flex;
        align-items: center;
        padding: 1.25rem;
        background-color: $dark;
        color: $white;
        border-radius: 0.625rem 0.625rem 0 0;
        .tdsh-back-button{
            display: flex;
            color: $white;
        }
        .tdsh-title{
            font-size: 1.125rem;
            font-weight: 500;
            margin-bottom: 0;
            margin-left: 0.5rem;
        }
    }
    .ticket-detail-side-content{
        display: flex;
        flex-direction: column;
        width: 100%;
        background-color: $white;
        border-radius: 0 0 0.625rem 0.625rem;
        padding: 1.25rem;
        .tdsc-list-item{
            display: flex;
            width: 100%;
            margin-bottom: 1rem;
            label{
                font-size: 1rem;
                font-weight: 500;
                color: $gray500;
                width: 6.25rem;
            }
            span{
                font-size: 1rem;
                font-weight: 500;
                color: $dark;
            }
        }
    }
}
.btn.btn-cancel-link.btn-link-black {
    text-decoration: none;
    font-size: 0.875rem;
    font-weight: 400;
}
.form-message-box{
    display: flex;
    width: 100%;
    justify-content: space-between;
    margin-bottom: 1rem;
    .message-field{
        display: flex;
        width: calc(100% - 2rem);
        flex-direction: column;
        .form-field{
            textarea.form-control{
                height: auto;
                font-size: 0.875rem;
                font-weight: 400;
                color: $dark;
                resize: none;
                max-height: inherit;
                font-family: $defaultfontfamily;
            }
        }
    }
    .attachment-file-field{
        display: flex;
        align-items: center;
        justify-content: center;
        position: relative;
        color: $gray500;
        width: 1.25rem;
        height: 1.25rem;
        margin-top: 1rem;
        .file-control{
            position: absolute;
            left: 0;
            right: 0;
            top: 0;
            bottom: 0;
            width: 100%;
            height: 100%;
            opacity: 0;
            cursor: pointer;
        }
    }
}
.attachment-gallery{
    display: flex;
    width: 100%;
    margin-bottom: 1rem;
    .attachment-gallery-thumb{
        display: flex;
        border: 1px solid $gray600;
        border-radius: 2px;
        max-width: 7.5rem;
        position: relative;
        .glythumb-close{
            position: absolute;
            right: 0.3125rem;
            top: 0.3125rem;
            width: 1.25rem;
            height: 1.25rem;
            min-width: 1.25rem;
            min-height: 1.25rem;
            border-radius: 50%;
            background-color: rgba($color: $purpleTaupe, $alpha: 0.5);
            color: $white;
            border: 0px;
            padding: 0;
        }
    }
}
.select-checkbox-field{
    .form-field{
        .select-style-container{
            .select-style__menu{
                .select-style__menu-list{
                    .select-style__option{
                        position: relative;
                        padding-left: 2.75rem;
                        &::before{
                            content: '';
                            position: absolute;
                            left: 0.625rem;
                            border-radius: 0.25rem;
                            border: 1px solid rgba(92, 80, 125, 0.70);
                            width: 1.375rem;
                            height: 1.375rem;
                        }
                        &.select-style__option--is-focused{
            
                        }
                        &.select-style__option--is-selected{
                            &::before{
                                background-color: $primary;
                                border-color: $primary;
                                background-image: url('../../../img/tick-option-icon.svg');
                                background-position: center center;
                                background-repeat: no-repeat;
                            }
                        }
                    }
                }
            } 
        }
    }
}
@media screen and (max-width: 767px) {
    .ticket-detail-main-wrapper{
        flex-direction: column;
        .ticket-detail-left{
            width: 100%;
            margin-bottom: 2rem;
        }
        .ticket-detail-right{
            width: 100%;
        }
    }
}