.section-hero-partnership{
    padding-top: 4.75rem;
    padding-bottom: 8.75rem;
    .hero-partnership-caption{
        padding-right: 4rem;
    }
    .hero-partnership-img{
        display: flex;
        flex-direction: column;
        background-color: rgba($color: $white, $alpha: 0.2);
        border-radius: 1.625rem;
        padding: 3.5rem 3rem;
    }
}
.section-why-partner-us{
    background-color: $white;
    padding-top: 7.5rem;
    padding-bottom: 0;
}
.section-eligibility{
    padding-top: 6.25rem;
    padding-bottom: 11rem;
    background-color: $white;
    background-image: url('../../../img/bg-star-eligibility-left.svg'), url('../../../img/bg-star-eligibility-right.svg');
    background-repeat: no-repeat;
    background-position: top 11rem left, bottom -20rem right;
}