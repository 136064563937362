@import url("https://fonts.googleapis.com/css2?family=DM+Sans:ital,wght@0,400;0,500;0,700;1,400;1,500;1,700&display=swap");

html {
  font-size: 16px;
}

body {
  background-color: $white;
  color: $dark;
  font-size: 1rem;
  line-height: 1.4;
  font-family: $defaultfontfamily;
  position: relative;

  &.body {
    &::before {
      content: "";
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      width: 100%;
      height: 100%;
      background: linear-gradient(133deg,
          rgba(167, 129, 202, 0.1) 0%,
          rgba(130, 85, 217, 0.1) 40%,
          rgba(33, 160, 168, 0.1) 55%,
          rgba(99, 212, 59, 0.1) 75%);
    }
  }

  &.buycrypto-page,
  &.sellcrypto-page {
    background: linear-gradient(180deg, #6532F8 0%, #230186 100%);

    &::before {
      content: "";
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      width: 100%;
      height: 100%;
      background-image: url('../../img/bg-buy.png');
      background-position: center top;
    }
  }

  &.home-page,
  &.partnership-page,
  &.aboutus-page {
    &::before {
      content: "";
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      width: 100%;
      height: 60rem;
      background: linear-gradient(133deg,
          rgba(167, 129, 202, 0.5) 0%,
          rgba(130, 85, 217, 0.5) 40%,
          rgba(33, 160, 168, 0.5) 55%,
          #63d43b80 75%);
    }
  }

  &.kyc-manual {
    &::before {
      content: "";
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      width: 100%;
      height: 100%;
      background: linear-gradient(133deg,
          rgba(167, 129, 202, 0.5) 0%,
          rgba(130, 85, 217, 0.5) 40%,
          rgba(33, 160, 168, 0.5) 55%,
          #63d43b80 75%);
    }
  }

  &.agreement-page {
    &::before {
      content: "";
      background-size: 100% 400px;
      height: 500 !important;
      position: absolute;
      top: 0;
      left: 0;
      background-image: url(../../../assets/img/support-background.png);
      background-repeat: no-repeat;
    }

    &::after {
      content: "";
      position: absolute;
      background-size: auto auto;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      background-image: url(../../img/support-icon.png);
      background-position: top right;
      background-repeat: no-repeat;
    }
  }

  &.become-a-partner-page {
    &::before {
      content: "";
      background-size: 100% auto;
      position: absolute;
      top: 0;
      left: 0;
      background-image: url(../../../assets/img/becomePartner/img2.png);
      background-repeat: no-repeat;
    }

    &::after {
      content: "";
      position: absolute;
      background-size: 100% auto;
      top: 40rem;
      left: 0;
      right: 0;
      bottom: 0;
      background-image: url(../../img/becomePartner/img1.png);
      background-position: right top;
      background-repeat: no-repeat;
    }
  }

  &.support-page {
    &::before {
      content: "";
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      width: 100%;
      height: 34rem;
      background: linear-gradient(133deg,
          rgba(167, 129, 202, 0.5) 0%,
          rgba(130, 85, 217, 0.5) 40%,
          rgba(33, 160, 168, 0.5) 55%,
          rgba(99, 212, 59, 0.5) 75%);
    }

    &::after {
      content: "";
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      opacity: 0.5;
      background-image: url("../../img/bg-support-img.svg");
      background-position: right top;
      background-repeat: no-repeat;
      mix-blend-mode: luminosity;
    }
  }
}

*,
::after,
::before {
  box-sizing: border-box;
}

.container {
  padding-left: 1rem;
  padding-right: 1rem;
  width: 100%;
  max-width: 1300px;
  margin-left: auto;
  margin-right: auto;
}

.container-width-1100 {
  max-width: 1100px;
}

.container-fluid {
  padding-left: 1rem;
  padding-right: 1rem;
  margin-left: auto;
  margin-right: auto;
  width: 100%;
  max-width: 1440px;
}

.text-capitalize {
  text-transform: capitalize;
}

.text-uppercase {
  text-transform: uppercase;
}

.text-center {
  text-align: center;
}

.text-left {
  text-align: left;
}

.text-right {
  text-align: right;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  margin: 0 0 0.625rem;
}

h1 {
  font-size: 3.375rem;
  font-weight: 700;
}

h2 {
  font-size: 2rem;
  font-weight: 500;
}

h3 {
  font-size: 1.5rem;
}

h4 {
  font-size: 1.25rem;
}

h5 {
  font-size: 1.125rem;
}

h6 {
  font-size: 1rem;
}

p {
  margin-top: 0;
}

legend {
  display: flex;
  font-size: 0.9375rem;
  font-weight: 700;
  color: $primary;
  margin-bottom: 0.8125rem;
  letter-spacing: 0.3px;
  text-transform: uppercase;
}

.font-size-12 {
  font-size: 0.75rem;
}

.font-size-13 {
  font-size: 0.8125rem;
}

.font-size-14 {
  font-size: 0.875rem;
}

.font-size-15 {
  font-size: 0.9375rem;
}

.font-weight-400 {
  font-weight: 400;
}

.font-weight-500 {
  font-weight: 500;
}

.font-weight-700 {
  font-weight: 700;
}

.h-100 {
  height: 100%;
}

/* Track */
::-webkit-scrollbar {
  width: 0.375rem;
  height: 0.375rem;
}

::-webkit-scrollbar-track {
  background-color: $primary;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background-color: $primary100;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background-color: #555;
}

.action-buttons {
  .btn {
    min-width: 13.75rem;
  }
}

.height-100 {
  height: 36rem;
  overflow-y: auto;
}

.text-primary {
  color: $primary;
}

.text-danger {
  color: $danger;
}

.text-dark-half {
  color: rgba($color: $dark, $alpha: 0.5);
}

.text-dark-100 {
  color: $dark100;
}

.position-relative {
  position: relative;
}

.row {
  &.row-mrl-5 {
    margin-left: -0.3125rem;
    margin-right: -0.3125rem;

    >* {
      padding-left: 0.3125rem;
      padding-right: 0.3125rem;
    }
  }

  &.row-mrl-6 {
    margin-left: -0.375rem;
    margin-right: -0.375rem;

    >* {
      padding-left: 0.375rem;
      padding-right: 0.375rem;
    }
  }
}

.page-title {
  font-size: 2.25rem;
  font-weight: 700;
  color: $dark100;
  margin-bottom: 0;
}

@media screen and (max-width: 1400px) {
  html {
    font-size: 15px;
  }

  body {
    &.become-a-partner-page {
      &::before {
        content: "";
        background-size: 100% auto;
        position: absolute;
        height: 20rem;
        top: 0;
        left: 0;
        background-image: url(../../../assets/img/becomePartner/img2.png);
        background-repeat: no-repeat;
      }

      &::after {
        content: "";
        position: absolute;
        background-size: 100% auto;
        top: 20rem;
        left: 0;
        right: 0;
        bottom: 0;
        background-image: url(../../../assets/img/becomePartner/img2.png);
        background-position: right top;
        background-repeat: no-repeat;
      }
    }
  }
}

@media screen and (max-width: 767px) {
  html {
    font-size: 14px;
  }

  body {
    &.become-a-partner-page {
      &::before {
        content: "";
        background-size: cover;
        position: absolute;
        height: 46em;
        top: 0;
        left: 0;
        background-image: url(../../../assets/img/becomePartner/img2.png);
        background-repeat: no-repeat;
      }

      &::after {
        display: none;
      }
    }
  }
}

.manual-kyc-form {
  background-color: white;
  border-radius: 20px;
  padding: 20px;
  margin-top: 10%;
}

.no-scroll {
  overflow-y: hidden;
}

@keyframes rotate {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.loading {
  animation: rotate 2s linear infinite;
}

.vertically-centered{
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.w-fit {
  width: fit-content;
}

.h-screen {
  height: 100vh;
}

#root {
  position: relative;
  z-index: auto !important;
}