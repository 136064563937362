.card-field {
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 100%;
  height: 5.625rem;
  border-radius: 0.625rem;
  padding: 0.875rem;
  border: 1px solid rgba($color: $black, $alpha: 0.2);
  background-color: rgba($color: $secondary, $alpha: 0.2);
  margin-bottom: 1rem;
  &.bg-light-green {
    background-color: rgba($color: $lightGreen, $alpha: 0.2);
  }
  .card-field-form-box {
    display: flex;
    align-items: center;
    position: relative;
    border-bottom: 1px solid rgba($color: $dark, $alpha: 0.2);
    .card-field-label {
      display: flex;
      align-items: center;
      color: $black;
      font-size: 0.75rem;
      font-weight: 500;
      margin-right: 1.5rem;
      margin-bottom: 0;
      img {
        max-width: 1.625rem;
      }
    }
  }
}
.fsc-card {
  display: flex;
  flex-direction: row-reverse;
  //   justify-content: center;
  width: 100%;
  // height: 100%;
  border-radius: 0.625rem;
  padding: 0.875rem;
  border: 1px solid rgba($color: $black, $alpha: 0.2);
  margin-bottom: 0.75rem;
  background-color: rgba($color: $secondary, $alpha: 0.2);
  &.bg-light-green {
    background-color: rgba($color: $lightGreen, $alpha: 0.2);
  }
  .fsc-label-text {
    color: rgba($color: $dark, $alpha: 0.6);
    // margin-bottom: 0.75rem;
    margin-left: 5rem;
    // justify-content: start;
  }
  label {
    font-size: 0.875rem;
    font-weight: 500;
    margin-bottom: 0;
  }
  .wa-text {
    font-size: 0.875rem;
    font-weight: 500;
    word-break: break-all;
  }
  .copy-clip-item {
    .icon-wrap {
      width: auto;
      height: auto;
    }
  }
}
.flow-box {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  position: relative;
  .mback-btn {
    position: absolute;
    left: 1.875rem;
    top: 1.875rem;
    color: $dark100;
  }
  .card-field {
    background-color: $light100;
    border-radius: 0.375rem;
    border: 0;
  }
}
.form-action-button{
    border-radius: 0px 0px 26px 26px;
    background: $white;
    box-shadow: 8px 0px 24px 0px rgba(0, 0, 0, 0.30);
    backdrop-filter: blur(20px);
}
@media screen and (max-width: 767px) {
  .card-field {
    .form-box {
    }
    .card-field-form-box {
      .card-field-label {
        display: flex;
        align-items: center;
        color: $black;
        font-size: 0.75rem;
        font-weight: 500;
        margin-right: 1.5rem;
        margin-bottom: 0;
        img {
          max-width: 1.625rem;
        }
      }
    }
  }
}

.review-voucher {
  flex: 1;
  margin-right: 10px;
}

.singleLine {
  display: inline;
  white-space: nowrap;
}