.receiver-chat-card{
    display: flex;
    width: 100%;
    flex-direction: column;
    padding: 1.5rem 1.875rem;
    .receiver-chat-heading{
        display: flex;
        align-items: center;
        width: 100%;
        margin-bottom: 0.625rem;
        .avatar{
            display: flex;
            align-items: center;
            margin-right: 0.75rem;
            .avatar-img{
                width: 2.25rem;
                height: 2.25rem;
                margin-right: 0.5rem;
                img{
                    width: 2.25rem;
                    height: 2.25rem;
                    border-radius: 50%;
                }
            }
            .avatar-name{
                display: flex;
                font-size: 1rem;
                font-weight: 700;
                color: $dark100;
                margin-bottom: 0;
            }
        }
        .date{
            display: flex;
            font-size: 0.75rem;
            font-weight: 500;
            color: $gray500;
            margin-bottom: 0;
        }
    }
    .receiver-chat-content{
        display: flex;
        flex-direction: column;
        width: 100%;
        font-size: 1rem;
        font-weight: 400;
        line-height: 1.5rem;
        color: $dark;
        padding-left: 2.625rem;
        .gallery-wrp{
            display: flex;
            flex-direction: column;
            width: 100%;
            .gallery-list-wrp{
                display: flex;
                list-style: none;
                margin: 0;
                padding: 0;
                gap: 0.625rem;
                .gallery-thumb-item{
                    display: flex;
                    flex-direction: column;
                    width: 100%;
                    max-width: 7.5rem;
                    img{
                        border-radius: 2px;
                    }
                }
            }
        }
    }
}