.avatar {
    position: relative;
    display: inline-block;
    background-color: rgba($color: $primary, $alpha: 0.12);
    border-radius: 6.25rem;
    padding: 4px;

    &--content {
        display: flex;
        flex-direction: row;
        align-items: center;
        &-img {
            display: flex;
            flex-direction: column;
            max-width: 2.125rem;
            max-height: 2.125rem;
        }
        &-name{
            font-size: 0.8125rem;
            font-weight: 700;
            color: $dark100;
            margin-right: 0.875rem;
            margin-left: 0.5rem;
            margin-bottom: 0;
        }
    }

    &--dropdown {
        display: none;
        position: absolute;
        background-color: #ffffff;
        width: max-content;
        min-width: 100px;
        box-shadow: 0px 8px 16px 0px rgba(0,0,0,0.2);
        z-index: 1;
        margin-top: 1px;
        border-radius: 0 0 10px 10px;
        border-top: 2px solid black;

        div {
            color: black;
            padding: 12px 16px;
            text-decoration: none;
            display: block;
            font-size: 14px;
        }
    }
}

.avatar:hover .avatar--dropdown {display: block;}