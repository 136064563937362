.partner-service-card{
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    padding: 2.5rem 2rem;
        .icon-wrap {
        width: 6.375rem;
        height: 6.375rem;
        border-radius: 100%;
        background-color: rgba($color: $secondary100, $alpha: 0.5);
        margin-bottom: 1rem;
    }
    .partner-service-text{
        display: flex;
        align-items: center;
        font-size: 1.25rem;
        font-weight: 500;
        line-height: 1.625rem;
        color: $dark;
        margin-bottom: 0.875rem;
        text-align: center;
        min-height: 3.75rem;
        max-width: 90%;
    }
    .partner-service-desc{
        font-size: 0.875rem;
        font-weight: 400;
        color: $dark;
        text-align: center;
    }
}