.step-item{
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    flex: 1;
    &:not(:first-child):before {
        content: '';
        width: 100%;
        height: 1px;
        background-color: $gray400;
        position: absolute;
        top: 9px;
        left: calc(-50% + 10px);
    }
    .step {
        display: flex;
        flex-direction: column;
        margin-bottom: 0.625rem;
        color: $gray400;
        position: relative;
        width: 100%;
        align-items: center;
        justify-content: center;
        z-index: 10;
        .dot{
            width: 1.25rem;
            height: 1.25rem;
            border-radius: 50%;
            border: 1px solid $gray400;
            background-color: $white;
        }
    }
    .step-title{
        font-size: 0.875rem;
        font-weight: 400;
        color: rgba($color: $dark100, $alpha: 0.5);
    }
    &.active{
        &:not(:first-child):before{
            background-color: $primary;
        }
        .step {
            .dot{
                background-color: $primary;
                border-color: $primary;
            }
        }
        .step-title{
            color: $dark100;
        }
    }
    &.complete{
        &:not(:first-child):before{
            background-color: $primary;
        }
        .step{
            .dot{
                background-color: $primary;
                border-color: $primary;
            }
        }
    }        
}