.status-wrapper{
    display: inline-flex;
    align-items: center;
    justify-content: center;
    padding: 0.3125rem 0.625rem;
    border-radius: 2.5rem;
    height: 1.5rem;
    &.status-approved{
        background-color: $success;
        color: $white;
    }
    &.status-rejected{
        background-color: $danger;
        color: $white;
    }
    .title{
        font-size: 0.6875rem;
        font-weight: 700;
    }
}