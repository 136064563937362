.section-hero-aboutus {
  padding-top: 5.5rem;
  padding-bottom: 8rem;

  .hero-aboutus-caption {
    .hero-caption-title {
      font-size: 3rem;
      line-height: 3.625rem;
    }
  }

  .hero-aboutus-left {
    display: flex;
    flex-direction: column;
    padding-right: 3.75rem;
  }

  .hero-aboutus-img {
    display: flex;
    flex-direction: column;
    background-color: rgba($color: $white, $alpha: 0.2);
    border-radius: 1.625rem;
    padding: 1.875rem 1.5rem;
  }
}

.section-book-a-call {
  background-color: $primary;
  color: $white;
  padding-top: 5rem;
  padding-bottom: 5rem;

  .section-title {
    font-size: 2.25rem;
    font-weight: 500;
    margin-bottom: 1.5rem;
    line-height: 3rem;
  }

  .btn {
    &.btn-white {
      color: $primary;
    }
  }
}

.section-how-we-plan {
  padding-top: 8.75rem;
  padding-bottom: 0;

  .section-content-left {
    padding-right: 6rem;
  }

  .section-right-image {
    display: flex;
    flex-direction: column;
    background-color: $light200;
    border-radius: 1.625rem;
    padding: 1.875rem 1.5rem;
  }
}

.section-mission-break-barriers {
  padding-top: 8.5rem;
  padding-bottom: 12rem;
  background-color: $white;
  background-image: url('../../../img/bg-star-eligibility-left.svg'), url('../../../img/bg-star-eligibility-right.svg');
  background-repeat: no-repeat;
  background-position: top 11rem left, bottom -20rem right;
}

.joinus-wrp {
  display: flex;
  flex-direction: column;
  padding: 6rem 4.5rem;
  width: 100%;
  border-radius: 1.625rem;
  background-color: rgba($color: $gray700, $alpha: 0.2);
  background-image: url('../../../img/joinus-bg-img.png');
  background-position: top center;
  background-repeat: no-repeat;
  margin-top: 2.5rem;

  .joinus-title {
    font-weight: 700;
    color: $dark200;
    margin-bottom: 0;
    text-align: center;
  }
}

@media screen and (max-width: 767px) {
  .section-hero-aboutus {
    padding-top: 1.5rem;
    padding-bottom: 1.5rem;

    .hero-aboutus-caption {
      .hero-caption-title {
        font-size: 2.5rem;
        line-height: 3rem;
      }
    }

    .hero-aboutus-left {
      padding-right: 0;
    }

    .hero-aboutus-img {
      padding: 1.5rem;
    }
  }

  .section-book-a-call {
    padding-top: 3rem;
    padding-bottom: 3rem;

    .section-title {
      font-size: 1.75rem;
      font-weight: 500;
      line-height: 2.5rem;
    }
  }

  .section-how-we-plan {
    padding-top: 3rem;

    .section-content-left {
      padding-right: 0;
    }

    .section-right-image {
      padding: 1.5rem;
    }
  }

  .section-mission-break-barriers {
    padding-top: 3rem;
    padding-bottom: 3rem;
  }

  .joinus-wrp {
    padding: 3rem;
    margin-bottom: 8rem;

    .joinus-title {
      font-size: 1.5rem;
      line-height: 1.4;
    }
  }
}