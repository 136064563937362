.header-wrapper {
  display: flex;
  flex-direction: column;
  width: 100%;
  padding-top: 1.25rem;
  margin-bottom: 1.5rem;
  .container {
    max-width: 1100px;
  }
  .header-main {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    background-color: $white;
    border-radius: 0.625rem;
    // border: 1px solid $gray100;
    padding: 0 1.875rem;
    position: relative;
    .navbar-wrapper {
      display: flex;
      align-items: center;
      justify-content: space-between;
      width: 100%;
      height: 100%;
      position: relative;
      .nav-left {
        display: flex;
        align-items: center;
        justify-content: space-between;
        .logo-wrp {
          display: flex;
          .logo-link {
            display: flex;
          }
        }
      }
      .nav-right {
        display: flex;
        .btn.nav-right-btn {
          padding-left: 2.75rem;
          padding-right: 2.75rem;
        }
        > * {
          margin-left: 0.5rem;
        }
      }
    }
  }
  .header-main1 {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    border-radius: 0.625rem;
    height: 44px;
    padding: 0 1.875rem;
    position: relative;
    background-color: black;

    @media (max-width: 424px) {
      height: 70px;
    }
    .navbar-wrapper {
      display: flex;
      align-items: center;
      justify-content: space-between;
      width: 100%;
      height: 100%;
      position: relative;
      .nav-left {
        display: flex;
        align-items: center;
        justify-content: space-between;
        .logo-wrp {
          display: flex;
          .logo-link {
            display: flex;
          }
          .kyc-text {
            display: flex;
            flex-direction: row;
            @media (max-width: 424px) {
              align-items: center;
            }
            .svg {
              margin-top: 10px;
            }
            .text {
              color: $white;
              margin-left: 20px;
              margin-top: 15px;
            }
          }
        }
      }
    }
    .button-kyc{
      background-color: transparent;
      color:$white;
      border: none;
      cursor: pointer;
    }
  }
}

.nav-slide {
  position: absolute;
  top: 100%;
  left: 0;
  right: 0;
  border-radius: 0.625rem;
  background-color: $white;
  margin-top: 1rem;
  padding: 2rem;
  z-index: 1;
  display: flex;
  flex-direction: column;
  width: 100%;
  box-shadow: 8px 8px 24px 0px rgba(0, 0, 0, 0.3);
  z-index: 99;

  .navbar-nav-list {
    margin-bottom: 0.5rem;
    display: flex;
    flex-direction: column;
    .nav-item {
      padding-left: 1rem;
      padding-right: 1rem;
      .nav-link {
        padding-top: 1rem;
        padding-bottom: 1rem;
      }
    }
  }
  .nav-right {
    margin-bottom: 0.625rem;
    display: flex;
    flex-direction: column;
    & > * {
      margin-bottom: 1rem;
    }
  }
}
@media screen and (max-width: 1024px) {
  .header-wrapper {
    padding-top: 0.5rem;
    margin-bottom: 0.5rem;
    .container {
      max-width: 100%;
    }
    .header-main {
      padding: 0 1rem;
      .navbar-wrapper {
        .nav-left {
          .logo-wrp {
            .logo-link {
              padding-top: 0.75rem;
              padding-bottom: 0.75rem;
            }
          }
        }
        .nav-right {
          display: flex;
          .btn.nav-right-btn {
            padding-left: 2.75rem;
            padding-right: 2.75rem;
          }
          > * {
            margin-left: 0.5rem;
          }
        }
      }
    }
  }
}
@media screen and (max-width: 767px) {
}
