.seize-box{
    display: flex;
    flex-direction: column;
    width: 100%;
    height: calc(100% - 1.5rem);
    margin-bottom: 1.5rem;
    .seize-card{
        display: flex;
        flex-direction: column;
        width: 100%;
        height: 100%;
        padding: 2.5rem 1.25rem;
        background-color: $lightGreen;
        color: $dark;
        border-radius: 0.5rem;
        position: relative;
        .seize-top{
            display: flex;
            align-items: flex-end;
            justify-content: space-between;
            .seize-heading{
                display: flex;
                flex-direction: column;
                padding-top: 1rem;
                width: 55%;
                .seize-title{
                    font-size: 1.25rem;
                    font-weight: 400;
                    margin-bottom: 1rem;
                }
            }
            .seize-icon{
                display: flex;
                width: 35%;
                position: absolute;
                right: 1.25rem;
                top: -0.75rem;
            }
        }
        .seize-desc{
            font-size: 0.875rem;
            font-weight: 400;
            margin-bottom: 0;
        }
    }
    &.seize-three{
        .seize-card{
            .seize-top{
                .seize-icon{
                    img{
                        mix-blend-mode: darken;
                    }
                }
            }
        }
    }
}