.section-support-search{
    padding-top: 6.5rem;
    padding-bottom: 8rem;
    position: relative;
    .supprt-search-title{
        font-size: 3rem;
        font-weight: 700;
        letter-spacing: -2px;
        color: $dark100;
        margin-bottom: 1.5rem;
    }
}
.section-support-content{
    background-color: $white;
}
.ticket-card{
    display: flex;
    align-items: center;
    justify-content: space-between;
    background-color: $lightGreen200;
    border-radius: 0.5rem;
    padding: 1.5rem 1.25rem;
    .ticket-left{
        display: flex;
        flex-direction: column;
        .ticket-title{
            font-size: 1.25rem;
            font-weight: 700;
        }
        .ticket-desc{
            font-size: 0.875rem;
            font-weight: 400;
            color: $purpleTaupe;
        }
    }
}
.mb-100{
    margin-bottom: 6.25rem;
}
.chat-bottom{
    position: fixed;
    bottom: 2.5rem;
    right: 2.5rem;
    z-index: 9;
    .btn{
        width: 4.125rem;
        height: 4.125rem;
    }
}
@media screen and (max-width: 767px) {
    .section-support-search{
        padding-top: 4rem;
        padding-bottom: 4rem;
        position: relative;
        .supprt-search-title{
            font-size: 3rem;
            font-weight: 700;
            letter-spacing: -2px;
            color: $dark100;
            margin-bottom: 1.5rem;
        }
    }
    .section-support-content{
        background-color: $white;
    }
    .ticket-card{
        padding: 1.5rem 1.25rem;
        margin-bottom: 2rem;
        flex-direction: column;
        .ticket-left{
            display: flex;
            flex-direction: column;
            margin-bottom: 1.5rem;
            .ticket-desc{
                font-size: 0.875rem;
                font-weight: 400;
                color: $purpleTaupe;
            }
        }
    }
    .chat-bottom{
        position: fixed;
        bottom: 2.5rem;
        right: 2.5rem;
        .btn{
            width: 4.125rem;
            height: 4.125rem;
        }
    }
}