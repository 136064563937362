.option-select-item{
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    .osi-left{
        display: flex;
        .osi-img{
            display: flex;
            flex-direction: column;
        }
        .osi-text{
            display: flex;
            flex-direction: column;
            justify-content: center;
            margin-left: 0.625rem;
            label{
                font-size: 0.875rem;
                font-weight: 500;
                margin-bottom: 0.25rem;
                color: $dark100;
                line-height: 1;
            }
            span{
                font-size: 0.75rem;
                font-weight: 500;
                color: rgba($color: $dark100, $alpha: 0.45);
                margin-bottom: 0;
                line-height: 1;
            }
        }
    }
    .osi-number{
        span{
            font-size: 0.75rem;
            font-weight: 500;
            color: rgba($color: $dark100, $alpha: 0.45);
            margin-bottom: 0;
            line-height: 1;
        }
        .osi-number-code{
            display: flex;
            align-items: center;
            justify-content: center;
            border-radius: 0.5rem;
            padding: 0.375rem 0.5rem;
            border: 1px solid #DDDDDD;
            background-color: rgba(162, 244, 227, 0.10);
            font-size: 0.75rem;
            font-weight: 500;
            line-height: 1;
            color: $purpleTaupe;
            text-transform: uppercase;
        }
    }
}