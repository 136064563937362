.tooltip-container {
    position: relative;
    display: flex;
    justify-items: center;
    align-items: center;
    cursor: pointer;
}

.tooltip-box {
    position: absolute;
    background-color: #333;
    color: #fff;
    padding: 5px 10px;
    border-radius: 4px;
    z-index: 10;
    white-space: nowrap;
    opacity: 0;
    visibility: hidden;
    transition: opacity 0.3s;
}

.tooltip-container:hover .tooltip-box {
    opacity: 1;
    visibility: visible;
}

.tooltip-top {
    bottom: 100%;
    left: 50%;
    transform: translateX(-50%);
    margin-bottom: 10px;
}

.tooltip-right {
    top: 50%;
    left: 100%;
    transform: translateY(-50%);
    margin-left: 10px;
}

.tooltip-bottom {
    top: 100%;
    left: 50%;
    transform: translateX(-50%);
    margin-top: 10px;
}

.tooltip-left {
    top: 50%;
    right: 100%;
    transform: translateY(-50%);
    margin-right: 10px;
}