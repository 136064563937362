$white: #ffffff;
$light200: #f8f9fa;
$gray700: #495057;
$dark200: #343a40;

.section {
  padding-top: 2.5rem;

  .content-wrapper {
    display: flex;
    flex-direction: column;
    padding: 4rem 3rem;
    width: 100%;
    border-radius: 1.625rem;
    background-color: rgba($color: $gray700, $alpha: 0.07);
    background-image: url("../../../img/joinus-bg-img.png");
    background-position: top center;
    background-repeat: no-repeat;
  }

  .caption-title {
    font-weight: 700;
    color: $dark200;
    margin-bottom: 0;
  }

  .caption-text {
    font-size: 1.125rem;
    line-height: 1.75;
    color: $gray700;
  }
}

.section-team-members {
  padding-top: 4rem;
  padding-bottom: 4rem;
  background-color: $light200;

  .section-title {
    font-size: 2.25rem;
    font-weight: 700;
    margin-bottom: 3rem;
  }

  .team-category-buttons {
    display: flex;
    justify-content: center;
    gap: 1rem;
    margin-bottom: 3rem;

    .btn {
      &:not(.btn-white) {
        background-color: #5A3CAE;
        border-color: #5A3CAE;
        color: #ffffff;
      }
    }
  }

  .row {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center; 
    width: 100%;
  }

  .team-member-card {
    background-color: $secondary400;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 2.5rem 3.5rem 1.875rem;
    border-radius: 0.5rem;
    height: calc(100% - 2rem);
    text-align: center;
    &:hover {
      transform: translateY(-10px);
    }

    .team-member-image {
      width: 150px;
      height: 150px;
      border-radius: 50%;
      overflow: hidden;
      margin-bottom: 1.5rem;

      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
    }

    .team-member-details {
      display: flex;
      flex-direction: column;
      align-items: center;

    .team-member-name {
      font-size: 1.5rem;
      font-weight: 700;
      white-space: nowrap;
    }

    .team-member-position {
      font-size: 1rem;
      white-space: nowrap;
      color: #5A3CAE;
    }
  }

    .team-member-social {
      display: flex;
      justify-content: center;
      align-items: center;
      gap: 1rem;
      margin-top: auto;
    }
  }
}

.section-join-our-team {
  padding-top: 4rem;
  padding-bottom: 4rem;
  background-color: $white;
  background-image: url("../../../img/bg-star-eligibility-right.svg");
  background-repeat: no-repeat;
  background-position: top 5rem right, bottom -20rem right;

  .section-title {
    font-size: 2.25rem;
    font-weight: 700;
    margin-bottom: 1.5rem;
  }
}

@media screen and (max-width: 767px) {
  .section {
    padding: 1.5rem;

    .caption-title {
      font-size: 2.5rem;
      line-height: 3rem;
    }

    .caption-text {
      font-size: 1rem;
      line-height: 1.5;
    }
  }

  .section-team-members {
    padding-top: 1.5rem;
    padding-bottom: 1.5rem;

    .section-title {
      font-size: 1.75rem;
    }

    .team-category-buttons {
      flex-direction: column;
      align-items: center;
    }
  }

  .section-join-our-team {
    padding-top: 3rem;
    padding-bottom: 3rem;

    .section-title {
      font-size: 1.75rem;
    }
  }
}