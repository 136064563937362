.phone-number-field{
    display: flex;
    width: 100%;
    margin: 4rem 0;
    .PhoneInputCountry{
        display: flex;
        height: 2.75rem;
        border-radius: 0.375rem;
        background-color: rgba($color: $dark100, $alpha: 0.08);
        margin-right: 0.5rem;
        padding: 0.5rem;
        .PhoneInputCountrySelect + .PhoneInputCountryIcon + .PhoneInputCountrySelectArrow{
            color: $primary;
        }
        .PhoneInputCountryIcon.PhoneInputCountryIcon--border{
            border: 0px;
            box-shadow: none;
        }
    }
    .PhoneInputInput{
        display: flex;
        width: 100%;
        height: 2.75rem;
        border-radius: 0.375rem;
        background-color: rgba($color: $dark100, $alpha: 0.08);
        border: 1px solid transparent;
        padding: 0.5rem 0.75rem;
        color: $dark;
        font-size: 0.875rem;
        font-weight: 400;
        line-height: 1.6875rem;
        outline: none;
    }
    &.PhoneInput--focus{
        .PhoneInputInput{
            border-color: $primary;
        }
    }
}

.otp-field {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    margin-top: 2.5rem;
    margin-bottom: 4rem;
    > * {
        margin-left: 0.25rem;
        margin-right: 0.25rem;
    }
    .otp-control {
        display: flex;
        width: 100% !important;
        height: 3.375rem !important;
        border-radius: 0.375rem;
        background-color: rgba($color: $dark100, $alpha: 0.08);
        border: 1px solid transparent;
        padding: 0.5rem 0.75rem;
        color: $dark;
        font-size: 1.875rem;
        font-weight: 500;
        line-height: 1.875rem;
        outline: none;
    }
    input[type=number]::-webkit-inner-spin-button, 
    input[type=number]::-webkit-outer-spin-button { 
        -webkit-appearance: none;
        -moz-appearance: none;
        appearance: none;
        margin: 0; 
    }

    /* Firefox */
    input[type=number] {
        -moz-appearance: textfield;
      }
}
@media screen and (max-width: 767px) {
    .phone-number-field{
        margin: 2rem 0;
        .PhoneInputCountry{
            height: 2.75rem;
            border-radius: 0.375rem;
        }
        .PhoneInputInput{
            height: 2.75rem;
            background-color: rgba($color: $dark100, $alpha: 0.08);
            border: 1px solid transparent;
            padding: 0.5rem 0.75rem;
            color: $dark;
            font-size: 0.875rem;
            font-weight: 400;
            line-height: 1.6875rem;
            outline: none;
        }
        &.PhoneInput--focus{
            .PhoneInputInput{
                border-color: $primary;
            }
        }
    }
}
