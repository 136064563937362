.accountdetails-field-table{
    line-height: 1;
    display: flex;
    align-items: flex-start;
    justify-content: center;
    height: 100%;
    .accountdetails-field-table-row{
        margin-bottom: 0.5rem;
        &:last-child{
            margin-bottom: 0;
        }
        .lable{
            font-weight: 500;
        }
    }
}
.no-row-data-list-wrap{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}
.ag-row{
    cursor: pointer;
    .ag-row-hover{
        .ag-cell{
            &.ag-cell-value{
                color: #5A3CAE;
            }
        }
    }
}
.ag-theme-alpine, 
.ag-theme-alpine-dark{
  --ag-alpine-active-color: $primary;
  --ag-selected-row-background-color: rgba($primaryrgba, 0.3);
  --ag-row-hover-color: #D4D4D433;
  --ag-column-hover-color: rgba($primaryrgba, 0.1);
  --ag-input-focus-border-color: rgba($primaryrgba, 0.4);
  --ag-range-selection-background-color: rgba($primaryrgba, 0.2);
  --ag-range-selection-background-color-2: rgba($primaryrgba, 0.36);
  --ag-range-selection-background-color-3: rgba($primaryrgba, 0.49);
  --ag-range-selection-background-color-4: rgba($primaryrgba, 0.59);
  --ag-background-color: $white;
  --ag-foreground-color: $secondary;
  --ag-border-color: #babfc7;
  --ag-secondary-border-color: #dde2eb;
  --ag-header-background-color: $light;
  --ag-tooltip-background-color: $light;
  --ag-odd-row-background-color: $light;
  --ag-control-panel-background-color: $light;
  --ag-subheader-background-color: $white;
  --ag-invalid-color: $danger;
  --ag-checkbox-unchecked-color: $gray;
  --ag-checkbox-background-color: var(--ag-background-color);
  --ag-checkbox-checked-color: var(--ag-alpine-active-color);
  --ag-range-selection-border-color: var(--ag-alpine-active-color);
  --ag-secondary-foreground-color: var(--ag-foreground-color);
  --ag-input-border-color: var(--ag-border-color);
  --ag-input-border-color-invalid: var(--ag-invalid-color);
  --ag-input-focus-box-shadow: 0 0 2px 0.1rem var(--ag-input-focus-border-color);
  --ag-disabled-foreground-color: rgba(24, 29, 31, 0.5);
  --ag-chip-background-color: rgba(24, 29, 31, 0.07);
  --ag-input-disabled-border-color: rgba(186, 191, 199, 0.3);
  --ag-input-disabled-background-color: rgba(186, 191, 199, 0.15);
  --ag-borders: solid 1px;
  --ag-border-radius: 3px;
  --ag-borders-side-button: none;
  --ag-side-button-selected-background-color: transparent;
  --ag-header-column-resize-handle-display: block;
  --ag-header-column-resize-handle-width: 2px;
  --ag-header-column-resize-handle-height: 30%;
  --ag-grid-size: 0.5rem;
  --ag-icon-size: 1rem;
  --ag-row-height: calc(var(--ag-grid-size) * 7);
  --ag-header-height: calc(var(--ag-grid-size) * 8);
  --ag-list-item-height: calc(var(--ag-grid-size) * 4);
  --ag-column-select-indent-size: var(--ag-icon-size);
  --ag-set-filter-indent-size: var(--ag-icon-size);
  --ag-cell-horizontal-padding: calc(var(--ag-grid-size) * 2);
  --ag-cell-widget-spacing: calc(var(--ag-grid-size) * 2);
  --ag-widget-container-vertical-padding: calc(var(--ag-grid-size) * 2);
  --ag-widget-container-horizontal-padding: calc(var(--ag-grid-size) * 2.5);
  --ag-widget-vertical-spacing: calc(var(--ag-grid-size) * 1.5);
  --ag-toggle-button-height: 18px;
  --ag-toggle-button-width: 28px;
  --ag-font-family: $defaultfontfamily;
  --ag-font-size: 1rem;
  --ag-icon-font-family: agGridAlpine;
  --ag-selected-tab-underline-color: var(--ag-alpine-active-color);
  --ag-selected-tab-underline-width: 2px;
  --ag-selected-tab-underline-transition-speed: 0.3s;
  --ag-tab-min-width: 240px;
  --ag-card-shadow: 0 1px 4px 1px rgba(186, 191, 199, 0.4);
  --ag-popup-shadow: var(--ag-card-shadow);
  --ag-side-bar-panel-width: 250px;
}
.ag-theme-alpine .ag-layout-auto-height .ag-center-cols-clipper{
    min-height: 25rem;
}
.card-table-wrapper{
    display: flex;
    flex-direction: column;
    width: 100%;
    border-radius: 0 0 0.625rem 0.625rem;
    background-color: $white;
    margin-bottom: 0.875rem;
    margin-left:-3px;
    overflow: hidden;
    .card-head-row{
        margin-top: 1.25rem;
        padding-left: 1.25rem;
        padding-right: 1.25rem;
        .card-head-title{
            margin-top: 0;
            margin-bottom: 0;
            padding: 0;
        }
    }
    .card-head-title{
        margin-top: 1.25rem;
        padding-left: 1.25rem;
        padding-right: 1.25rem;
        margin-bottom: 0;
        font-size: 1.125rem;
        font-weight: 700;
        color: $dark;
    }
    .ag-root-wrapper{
        border: 0px;
        background-color: transparent;
        .ag-header{
            background-color: $white;
            border-bottom: 0;
            .ag-header-cell-text{
                font-size: 0.875rem;
                font-weight: 500;
                color: $white;
            }
        }
        .ag-body{
            .ag-row{
                font-size: 0.875rem;
                font-weight: 500;
                border-color: rgba($color: $dark100, $alpha: 0.2);
                display: flex;
                align-items: center;
            }
            .ag-row-even{
                background-color: $white;
            }
            .ag-row-odd{
                background-color: $white;
            }
        }
        .actions{
            height: 100%;
        }
    }

    .card-horizontal-item{
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
    }
}
.ag-header-row.ag-header-row-column{
    background-color: $dark;
    border-radius: 0.625rem 0.625rem 0 0;
    width:fit-content;
}
.ag-header-cell-resize::after{
    background-color: $dark;
}
.ag-cell-wrapper{
    height: 100%;
}
.bg-status-approved,
.bg-status-rejected,
.bg-status-pending,
.bg-status-open,
.bg-status-in-progess,
.bg-status-closed{
    display: inline-flex;
    align-items: center;
    justify-content: center;
    padding: 0.3125rem 0.625rem;
    border-radius: 2.5rem;
    width: auto !important;
    height: 1.5rem;
    font-size: 0.6875rem;
    font-weight: 700;
}
.bg-status-open,
.bg-status-in-progess,
.bg-status-closed{
    min-width: 4.5rem;
}
.bg-status-approved{
    background-color: $success;
    color: $white;
}
.bg-status-rejected{
    background-color: $danger;
    color: $white;
}
.bg-status-pending{
    background-color: $gray300;
    color: $white;
}
.bg-status-open{
    background-color: $orange;
    color: $white;
}
.bg-status-in-progess{
    background-color: $green;
    color: $white;
}
.bg-status-closed{
    background-color: $gray500;
    color: $white;
}
.filter-list-wrp{
    .filter-list-left{
        .page-title{
            margin-right: 1.125rem;
        }
        & > *{
            margin-right: 0.5rem;
        }
    }
    .filter-list-right{
        & > *{
            margin-left: 0.5rem;
        }
    }
}
.filter-show-dropdown{
    display: flex;
    align-items: center;
    label{
        margin-right: 0.5rem;
        color: $dark;
        font-size: 0.875rem;
        font-weight: 400;
    }
}
.search-list-wrap{
    .form-field{
        margin-bottom: 0;
        .form-control{
            width: 20rem;
            background-color: $white;
            border-radius: 4.75rem;
            &::placeholder{
                color: rgba($color: $dark, $alpha: 0.3);
            }
            background-image: url('../../../img/search-icon.svg');
            background-position: right 0.75rem center;
            background-repeat: no-repeat;
            &::-webkit-search-decoration,
            &::-webkit-search-cancel-button,
            &::-webkit-search-results-button,
            &::-webkit-search-results-decoration {
                display: none; 
            }
        }
    }
}
@media screen and (max-width: 767px) {
    .search-list-wrap{
        .form-field{
            .form-control{
                width: 100%;
            }
        }
    } 
    .filter-list-wrp{
        flex-direction: column;
        .filter-list-left{
            display: flex;
            justify-content: space-between;
            margin-bottom: 1rem;
            .page-title{
                font-size: 1.75rem;
                margin-right: 1.125rem;
            }
        }
        .filter-list-right{
            display: flex;
            justify-content: space-between;
            & > *{
                margin-left: 0.5rem;
                &:first-child{
                    margin-left: 0;
                }
            }
        }
    }
}