.review-purchase-wrap{
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100%;
    position: relative;
    .mback-btn{
        position: absolute;
        left: 1.875rem;
        top: 1.875rem;
        color: $dark100;
    }
    .card-field{
        background-color: $light100;
        border-radius: 0.375rem;
        border: 0;
    }
}