.form-field{
    display: flex;
    flex-direction: column;
    margin-bottom: 1rem;
    .form-label{
        display: flex;
        flex-direction: column;
        font-size: 0.8125rem;
        font-weight: 400;
        margin-bottom: 0.25rem;
        color: rgba($color: $dark, $alpha: 0.7);
    }
    .form-control{
        width: 100%;
        background-color: $light100;
        color: $dark;
        border: 1px solid $light100;
        padding: 0.5rem 0.75rem;
        height: 2.75rem;
        border-radius: 0.375rem;
        font-size: 0.875rem;
        font-weight: 400;
        box-shadow: none;
        outline: none;
        &:-internal-autofill-selected{
            background-color: transparent !important;
        }
        &:focus{
            outline: 1px solid $dark;
        }
        &.form-control-value{
            // border-color: $dark;
        }
        &::placeholder{
            color: $dark;
            font-family: $defaultfontfamily;
        }
    }
    textarea{
        &.form-control{
            height: auto;
        }
    }
    .message-error{
        color: $danger;
    }
    &.form-field-secondary{
        .form-control{
            border-width: 0;
            background-color: transparent;
            border-radius: 0;
            padding: 0.5rem 0;
            font-size: 0.875rem;
            font-weight: 500;
            height: auto;
            &::placeholder{
                color: $gray;
            }
            &:focus{
                outline: none;
            }
        }
        select{
            &.form-control{
                border-width: 1px;
                border-radius: 1.25rem;
                border-style: solid;
                border-color: rgba($color: $dark, $alpha: 0.2);
                padding: 0.5rem 1rem;
            }
        }
        .message-error{
            margin-top: -0.75rem;
            small{
                font-size: 0.75rem;
            }
        }
        .select-style-container{
            .select-style__control{
                border-color: rgba($color: $dark, $alpha: 0.2);
                border-radius: 1.25rem;
                background-color: transparent;
                box-shadow: none;
                min-height: 2.25rem;
                &.select-style__control--is-focused{
                    border-color: $dark;
                }
                .select-style__single-value{
                    color: $dark100;
                    font-weight: 500;
                    font-size: 0.875rem;
                    .option-select-item{
                        .osi-left{
                            .osi-img{
                                display: none;
                                img{
                                    max-width: 1.5rem;
                                }
                            }
                            .osi-text{
                                label{
                                    margin-bottom: 0;
                                }
                                span{
                                    display: none;
                                }
                            }
                        }
                        .osi-number{
                            display: none;
                        }
                    }
                }
            }
        }
    }
    .select-style-container{
        .select-style__control{
            background-color: $light100;
            border-color: $light100;
            border-radius: 0.375rem;
            box-shadow: none;
            min-height: 2.75rem;
            &.select-style__control--is-focused{
                border-color: $dark100;
            }
            .select-style__single-value{
                color: $dark100;
                font-weight: 500;
                font-size: 0.875rem;
                .option-select-item{
                    .osi-left{
                        .osi-img{
                            display: none;
                            img{
                                max-width: 1.5rem;
                            }
                        }
                        .osi-text{
                            label{
                                margin-bottom: 0;
                            }
                            span{
                                display: none;
                            }
                        }
                    }
                    .osi-number{
                        display: none;
                    }
                }
            }
        }
        .select-style__menu{
            min-width: 18.75rem;
            margin: 0;
            border-radius: 0.375rem;
            background: $white;
            box-shadow: 8px 8px 24px 0px rgba(0, 0, 0, 0.30);
            // backdrop-filter: blur(20px);
            .select-style__menu-list{
                padding: 0.25rem 0;
                max-height: 11.5rem;
                .select-style__option{
                    color: $dark100;
                    font-size: 0.875rem;
                    font-weight: 500;
                    padding: 0.875rem 1.25rem;
                    border-bottom: 1px solid rgba($color: $dark100, $alpha: 0.1);
                    &:last-child{
                        border-bottom: 0;
                    }
                    &.select-style__option--is-selected{
                        background-color: rgba($color: $primary, $alpha: 0.3);
                        color: $white;
                    }
                    &.select-style__option--is-focused{
                        background-color: rgba($color: $dark100, $alpha: 0.1);
                        color: $dark;
                    }
                }
            }
        }
    }
    &.form-file-field{
        &.file-add-attachment{
            .form-file-main{
                border-color: #5C507DB2;
                .attachment-content{
                .attachment-button{
                    background-color: $dark100;
                    color: $white;
                }
            }
            }
        }
        .form-file-main{
            position: relative;
            display: flex;
            flex-direction: column;
            border: 1px dashed $primary;
            background-color: $light100;
            border-radius: 0.75rem;
            .form-control{
                opacity: 0;
                height: 9.5rem;
                width: 100%;
                position: relative;
                z-index: 2;
                cursor: pointer;
            }
            .attachment-content{
                display: flex;
                flex-direction: column;
                justify-content: center;
                align-items: center;
                position: absolute;
                top: 0;
                bottom: 0;
                right: 0;
                left: 0;
                z-index: 1;
                padding: 1.5rem 1rem 1.125rem;
                .attachment-icon{
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    width: 2.5rem;
                    height: 2.5rem;
                    color: rgba($color: $black, $alpha: 0.4);
                    margin-bottom: 1.25rem;
                }
                .attachment-text-title{
                    font-size: 0.875rem;
                    font-weight: 500;
                    margin-bottom: 0.125rem;
                }
                .attachment-text-or{
                    font-size: 0.75rem;
                    font-weight: 400;
                    color: rgba($color: $dark, $alpha: 0.7);
                    margin-bottom: 0.375rem;
                }
                .attachment-button{
                    font-size: 0.75rem;
                    font-weight: 500;
                    color: $white;
                    background-color: $primary;
                    margin-bottom: 0;
                    padding: 0.5rem 1.25rem;
                    border-radius: 6.25rem;
                }
            }
        }
    }
}
.message-error{
    color: $danger;
}
.form-control-hide-increment {
    input::-webkit-outer-spin-button,
    input::-webkit-inner-spin-button {
        -webkit-appearance: none;
        margin: 0;
    }

    /* Firefox */
    input[type=number] {
      -moz-appearance: textfield;
    }
}