.radio-button-card {
    display: flex;
    align-items: center;
    position: relative;
    width: 100%;
    &.active {
      .rb-card{
        .rb-icon{
            border-color: $primary;
        }
        .radio-title{
            color: $primary;
        }
      }
    }
    .radio-button-field {
      position: absolute;
      left: 0;
      right: 0;
      bottom: 0;
      top: 0;
      cursor: pointer;
      opacity: 0;
      margin: 0;
      padding: 0;
      width: 100%;
      height: 100%;
    }
    .rb-card{
        display: flex;
        flex-direction: column;
        align-items: center;
        width: 100%;
        .rb-icon{
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            width: 100%;
            min-height: 3.75rem;
            background-color: $light100;
            border-radius: 0.375rem;
            padding: 1rem;
            border: 1px solid transparent;
            margin-bottom: 0.25rem;
        }
        .radio-title{
            color: $dark;
            font-weight: 400;
            font-size: 0.75rem;
            line-height: 0.8125rem;
            margin: 0;
        }
    }
  }
  