.service-card{
    display: flex;
    flex-direction: column;
    width: 100%;
    padding: 2rem 1.25rem;
    position: relative;
    background: linear-gradient(180deg, $secondary100 0%, $primary100 100%);
    border-radius: 0.5rem;
    overflow: hidden;
    &::before{
        content: '';
        position: absolute;
        top: 0;
        bottom: 0;
        right: 0;
        left: 0;
        background-image: url('../../../img/bg-service-box.svg');
        background-repeat: no-repeat;
        background-position: left -50px top -140px;
        z-index: 2;
    }
    &::after{
        content: '';
        position: absolute;
        top: 3px;
        bottom: 3px;
        right: 3px;
        left: 3px;
        background-color: $secondary100;
        border-radius: 0.5rem;
        z-index: 1;
    }
    &.service-odd{
        background: linear-gradient(180deg, $lightGreen200 0%, $lightGreen300 100%);
        &::after{
            background-color: $lightGreen100;
        }
    }
    .service-main{
        display: flex;
        flex-direction: column;
        position: relative;
        z-index: 3;
    }
    .service-icon{
        width: 3.625rem;
        height: 3.625rem;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        margin-bottom: 3rem;
        border-radius: 0.25rem;
        background-color: $white;
        box-shadow: 0px 4px 24px 0px rgba(0, 0, 0, 0.25);
    }
    .service-text{
        font-size: 1.25rem;
        font-weight: 500;
        color: $dark;
        margin-bottom: 0;
        min-height: 4rem;
    }
}
@media screen and (min-width: 1025px) and (max-width: 1240px) {
    .service-card{
        .service-text{
            font-size: 1rem;
            min-height: 4rem;
        }
    }
}
@media screen and (max-width: 1024px) {
    .service-card{
        padding: 2rem 1rem;
        .service-icon{
            margin-bottom: 2rem;
        }
        .service-text{
            font-size: 0.8125rem;
            font-weight: 500;
            color: $dark;
            margin-bottom: 0;
            min-height: 3.125rem;
        }
    }
}
@media screen and (max-width: 767px) {
    .service-card{
        padding: 2rem 2rem;
        margin-bottom: 1.25rem;
        &::after{
            content: '';
            position: absolute;
            top: 3px;
            bottom: 3px;
            right: 3px;
            left: 3px;
            background-color: $secondary100;
            border-radius: 0.5rem;
            z-index: 1;
        }
        &.service-odd{
            background: linear-gradient(180deg, $lightGreen200 0%, $lightGreen300 100%);
            &::after{
                background-color: $lightGreen100;
            }
        }
        .service-main{
            display: flex;
            flex-direction: column;
            position: relative;
            z-index: 3;
        }
        .service-icon{
            width: 3.625rem;
            height: 3.625rem;
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            margin-bottom: 3rem;
            border-radius: 0.25rem;
            background-color: $white;
            box-shadow: 0px 4px 24px 0px rgba(0, 0, 0, 0.25);
        }
        .service-text{
            font-size: 1.25rem;
            font-weight: 500;
            color: $dark;
            margin-bottom: 0;
        }
    }
}