.select-bank-radio-card{
    display: flex;
    align-items: center;
    position: relative;
    width: 100%;
    margin-bottom: 0.625rem;
    &:last-child{
        margin-bottom: 0;
    }
    &.active {
      .sbr-card{
          border-color: $primary;
      }
    }
    &.readonly{
        .sbr-card{
            border-color: $light100;
            height: 2.75rem;
        }
    }
    .select-bank-radio-field{
      position: absolute;
      left: 0;
      right: 0;
      bottom: 0;
      top: 0;
      cursor: pointer;
      opacity: 0;
      margin: 0;
      padding: 0;
      width: 100%;
      height: 100%;
    }
    .sbr-card{
        display: flex;
        align-items: center;
        justify-content: space-between;
        width: 100%;
        background-color: $light100;
        padding: 0.5rem 0.75rem;
        border-radius: 0.375rem;
        border: 1px solid transparent;
        .sbr-left{
            display: flex;
            align-items: center;
            .sbr-icon{
                display: flex;
                flex-direction: column;
                align-items: center;
                justify-content: center;
                margin-right: 0.375rem;
            }
        }
        .sbr-text{
            font-size: 0.875rem;
            font-weight: 400;
            color: $dark;
            line-height: 0.8125rem;
            margin: 0;
        }
    }
  }
  