.search-cryptocurrencies-wrapper{
    display: flex;
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background-color: $white;
    border-radius: 2.25rem;
    padding: 1.25rem;
    .search-cryptocurrencies-main{
        display: flex;
        flex-direction: column;
        width: 100%;
    }
    .form-box{
        width: 100%;
        .form-field{
            .select-style-container{
                .select-style__control{
                    background-color: transparent;
                    border: 1px solid rgba($color: $black, $alpha: 0.2);
                    border-radius: 0.5rem;
                    min-height: 2.25rem;
                }
                .select-style__indicators{
                    display: none;
                }
                .select-style__menu{
                    min-width: 100%;
                    background-color: transparent;
                    border-radius: 0;
                    box-shadow: none;
                    .select-style__menu-list{
                        max-height: 22rem;
                        /* Track */
                        &::-webkit-scrollbar {
                            width: 0.25rem;
                            height: 0.25rem;
                        }
                        &::-webkit-scrollbar-track {
                            background-color: $white;
                        }
                        /* Handle */
                        &::-webkit-scrollbar-thumb {
                            background-color: #A9A5B3;
                        }
                        /* Handle on hover */
                        &::-webkit-scrollbar-thumb:hover {
                            background-color: #555;
                        }
                    }
                } 
            }
        } 
    }
}