.search-form{
    width: 100%;
    .form-search-box{
        display: flex;
        align-items: center;
        position: relative;
        .form-field{
            width: 100%;
            .form-control{
                padding-right: 4.25rem;
            }
        }
        .btn{
            background-color: $dark100;
            border-color: $dark100;
            border-radius: 0.375rem;
            padding: 0.875rem 1rem;
            height: 2.75rem;
            position: absolute;
            right: 0;
            top: 0;
        }
    }
}