.fadeinout{
    display: flex;
    flex-direction: column;
    width: 100%;
    animation: fade 1.5s ease-in-out;
    opacity: 0;
    &.show{
        transform: translate(0, 0);
        opacity: 1;
    }
    @keyframes fade {
        0% { opacity: 0 }
        50% { opacity: 1 }
    }
}