.navbar-nav-list{
    display: flex;
    align-items: center;
    margin: 0;
    padding: 0;
    list-style: none;
    height: 100%;
    .nav-item{
        display: flex;
        padding-left: 1.25rem;
        padding-right: 1.25rem;
        height: 100%;
        .nav-link{
            display: flex;
            align-items: center;
            font-size: 0.875rem;
            font-weight: 700;
            color: $dark100;
            text-decoration: none;
            transition: all ease-in 0.4s;
            position: relative;
            height: 100%;
            padding: 1.75rem 0px;
            justify-content: center;
            &::after{
                content: '';
                position: absolute;
                bottom: 0;
                height: 6px;
                width: 0%;
                background-color: transparent;
                border-radius: 0.25rem;
                transition:  width ease-in-out 0.2s;
            }
            &:hover,
            &.active{
                &::after{
                    width: 100%;
                    background-color: $primary;
                }
            }
        }
    }
}