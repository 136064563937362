.section-wrapper{
    display: flex;
    flex-direction: column;
    width: 100%;
    padding-top: 4.375rem;
    padding-bottom: 4.375rem;
}

@media screen and (max-width: 1024px) {
    .section-wrapper{
        padding-top: 3rem;
        padding-bottom: 3rem;
    }
}