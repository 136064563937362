.icon-text-card{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    min-height: 26rem;
    .itc-img{
        display: flex;
        flex-direction: column;
        max-width: 120px;
        margin-bottom: 1.25rem;
    }
    .itc-text{
        font-size: 1.5rem;
        font-weight: 700;
        color: $dark100;
    }
}