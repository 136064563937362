/* Default styles for all screen sizes */
.mt {
  margin-top: 15rem;
}

/* Media query for screens with a maximum width of 768px (small screens) */
@media screen and (max-width: 768px) {
  .mt {
    margin-top: 19rem; /* Apply 10rem margin for smaller screens */
  }
}

/* Media query for screens with a minimum width of 769px (medium and larger screens) */
@media screen and (min-width: 769px) {
  .mt {
    margin-top: 17rem; /* Apply 20rem margin for medium and larger screens */
  }
}
