.nav-list-wrapper{
    display: flex;
    flex-direction: column;
    width: 100%;
    margin: 0;
    padding: 0;
    list-style: none;
    .nav-list-item{
        display: flex;
        margin-bottom: 1rem;
        .nav-list-item-link{
            color: rgba($color: $white, $alpha: 0.6);
            font-size: 0.9375rem;
            font-weight: 400;
            display: flex;
            text-decoration: none;
            transition: all ease-in 0.2s;
            &:hover{
                color: $white;
                padding-left: 0.25rem;
            }
        }
    }
}