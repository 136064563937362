.copyclip-position-relative{
    position: relative;
    .form-field .form-control.form-control-value{
        border-color: $light100;
        padding-right: 2.5rem;
    }
    .copy-clip-item{
        position: absolute;
        top: 2.375rem;
        right: 0.875rem;
        cursor: pointer;
        .icon-wrap{
            width: 1.125rem;
            height: 1.125rem;
            color: rgba($color: $dark, $alpha: 0.5);
        }
    }
}