.eligibility-card{
    display: flex;
    flex-direction: column;
    padding: 2.75rem 1.875rem 4rem;
    background-color: $lightGreen200;
    border-radius: 0.5rem;
    height: calc(100% - 1.5rem);
    margin-bottom: 1.5rem;
    .eligibility-img-icon{
        display: flex;
        flex-direction: column;
        margin-bottom: 0.625rem;
        align-items: flex-start;
        min-height: 8.75rem;
    }
    .eligibility-text{
        font-size: 1.25rem;
        font-weight: 400;
        color: $dark;
        margin-bottom: 1.125rem;
    }
    .eligibility-desc{
        font-size: 0.875rem;
        font-weight: 400;
        color: $dark;
        margin-bottom: 0;
    }
}