.social-media-wap{
    display: flex;
    align-items: center;
    width: 100%;
    .sm-item-link{
        display: flex;
        margin-right: 1rem;
        color: rgba($color: $white, $alpha: 0.4);
        transition: all ease-in 0.2s;
        &:hover{
            color: $white;
        }
    }
}