.accordion-item{
    display: flex;
    flex-direction: column;
    width: 100%;
    border-top: 1px solid rgba($color: $black, $alpha: 0.1);
    &:first-child{
        border-top: 0px;
    }
    .accordion-heading{
        display: flex;
        align-items: center;
        justify-content: space-between;
        cursor: pointer;
        padding: 1.25rem 0;
        .accordion-heading-title{
            font-weight: 400;
            margin-bottom: 0;
            font-size: 1.125rem;
            color: $dark;
        }        
        .accordion-head-right{
            display: flex;
            align-items: center;
            justify-content: flex-end;
            .accordion-arrow-icon,
            & > .btn{
                width: 1rem;
                height: 1rem;
                display: flex;
                align-items: center;
                justify-content: center;
                color: $purpleTaupe;
            }
            & > *{
                margin-left: 1rem;
                &:first-child{
                    margin-left: 0;
                }
            }
        }
    }
    .accordion-content{
        display: flex;
        flex-direction: column;
        width: 100%;
        color: $purpleTaupe;
        font-size: 0.875rem;
        margin-bottom: 1.25rem;
        max-width: 70%;
    }
  }
  @media screen and (max-width: 767px) {
    .accordion-item{
        .accordion-heading{ 
            padding: 1.25rem 0;
            .accordion-heading-title{
                font-size: 1rem;
                font-weight: 500;
            }
        }
        .accordion-content{
            font-size: 0.875rem;
            margin-bottom: 1.25rem;
            max-width: 90%;
        }
    }
  }