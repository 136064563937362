.section-hero{
    padding-top: 0;
    padding-bottom: 0;
    &.section-new-hero{
        justify-content: center;
        align-items: center;
        min-height: 900px;
        .hero-form-wrapper{
            &::before{
                background-image: url('../../../img/bg-hero-new.webp');
                mix-blend-mode: inherit;
                opacity: 1;
            }
        }
    }
    .hero-title{
        color: $dark100;
        margin-bottom: 1rem;
        text-align: center;
        font-size: 3.375rem;
        line-height: 1;
        strong{
            color: $primary;
        }
    }
    .hero-desc{
        text-align: center;
        margin-bottom: 1.75rem;
    }
    .hero-form-wrapper{
        display: flex;
        flex-direction: column;
        width: 100%;
        position: relative;
        padding-bottom: 3.625rem;
        &::before{
            content: '';
            position: absolute;
            top: 0;
            left: 0;
            right: 0;
            bottom: 0;
            background-image: url('../../../img/bg-hero.svg');
            background-position: top 70px center;
            background-repeat: no-repeat;
            mix-blend-mode: luminosity;
            opacity: 0.5;
            z-index: 1;
        }
        .hero-form-main{
            width: 100%;
            max-width: 30rem;
            margin-left: auto;
            margin-right: auto;
            position: relative;
            z-index: 2;
        }
    }
}
.section-ourclient{
    position: relative;
    padding-top: 3rem;
    padding-bottom: 3rem;
    overflow: hidden;
    &::before,
    &::after{
        content: '';
        position: absolute;
        width: 100%;
        top: 0;
        bottom: 0;
        right: 0;
        left: 0;
    }
    &::before{
        background-color: $lightGreen100;
        transform: rotate(-178.76deg);
    }
    &::after{
        background-color: $secondary100;
        transform: rotate(178.73deg);
    }
    .ourclient-main-wrapper{
        display: flex;
        flex-direction: column;
        width: 100%;
        position: relative;
        z-index: 2;
    }
}
.section-service{
    background-color: $white;
    padding-top: 10rem;
}
.section-whous{
    background-color: $secondary100;
    padding-top: 0;
    padding-bottom: 0;
    .whous-left{
        padding-top: 4.5rem;
        padding-bottom: 4.5rem;
        height: 100%;
        justify-content: center;
    }
}
.section-seize{
    background-image: url('../../../img/bg-star-seiz.svg');
    background-position: left center;
    background-repeat: no-repeat;
    padding-bottom: 8.75rem;
}
.section-faqs{
    background-image: url('../../../img/bg-star-faq.svg');
    background-position: right center;
    background-repeat: no-repeat;
}

@media screen and (max-width: 1024px) {
    .section-service{
        padding-top: 3rem;
    }
    .section-seize{
        padding-bottom: 3rem;
    }
}
@media screen and (max-width: 767px){
    .section-hero{
        .hero-title{
            font-size: 3rem;
            margin-top: 1.5rem;
        }
        .hero-form-wrapper{
            padding-bottom: 3.625rem;
        }
    }
    
    .section-service{
        padding-top: 3rem;
    }
    .section-seize{
        background-size: 50%;
        padding-bottom: 3rem;
    }
    .section-faqs{
        background-size: 50%;
    }
}

@media screen and (max-width: 320px){
    .section-hero{
        .hero-title{
            font-size: 2.5rem;
        }
        .hero-form-wrapper{
            padding-bottom: 3.5rem;
        }
    }
}