.modal-wrapper{
    display: flex;
    flex-direction: column;
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: 99;
    background-color: rgba($color: $black, $alpha: 0.7);
    padding-top: 1rem;
    padding-bottom: 1rem;
    align-items: center;
    justify-content: center;
    &.modal-small{
        .modal-main-wrapper{
            max-width: 31.875rem;
        }
    }
    &.modal-small-medium{
        .modal-main-wrapper{
            max-width: 30rem;
        }
    }
    &.modal-min-height{
        .modal-main-wrapper{
            .modal-content-wrapper{
                // min-height: 36rem;
            }
        }
    }
    .modal-main-wrapper{
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-content: center;
        margin-left: auto;
        margin-right: auto;
        width: 100%;
        height: 100%;
        max-width: 46.375rem;
        .modal-content-wrapper{
            display: flex;
            flex-direction: column;
            width: 100%;
            max-height: 100%;
            overflow-y: auto;
            background-color: $white;
            border-radius: 1.625rem;
            color: $dark100;
            position: relative;
            box-shadow: 8px 8px 24px 0px rgba(0, 0, 0, 0.30);
            // backdrop-filter: blur(20px);
            .action-buttons{
                width: 100%;
            }
            .modal-close{
                position: absolute;
                right: 1.875rem;
                top: 1.875rem;
                width: 1rem;
                height: 1rem;
                border-radius: 50%;
                background-color: rgba($color: $secondary100, $alpha: 0.05);
                display: flex;
                align-items: center;
                justify-content: center;
                z-index: 1;
                cursor: pointer;
            }
        }
    }
}
@media screen and (max-width: 767px) {
    .modal-wrapper{
        &.modal-small{
            .modal-main-wrapper{
                max-width: 90%;
            }
        }
        &.modal-small-medium{
            .modal-main-wrapper{
                max-width: 90%;
            }
        }
        .modal-main-wrapper{
            max-width: 90%;
        }
    }
}