.buysell-navmenu-wrp{
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    border-radius: 2.25rem;
    background: rgba(12, 0, 45, 0.50);
    box-shadow: 0px 0px 20px 0px rgba(0, 0, 0, 0.10);
    .icon-wrap{
        width: 2.75rem;
        height: 2.75rem;
        margin-bottom: 0.75rem;
        margin-right: 1.125rem;
    }
}
.nav-text-icon-list-wrp{
    display: flex;
    flex-direction: column;
    width: 100%;
    background-color: $white;
    border-radius: 2.25rem;
    padding: 2.5rem 1.25rem;
    .nav-til-wrapper{
        display: flex;
        width: 100%;
        list-style: none;
        margin: 0;
        padding: 0;
        flex-wrap: wrap;
        .nav-til-item{
            display: flex;
            flex-direction: column;
            width: 25%;
            padding: 1.25rem;
            position: relative;
            &:nth-child(1), &:nth-child(2), &:nth-child(3){
                &::before{
                    content: '';
                    position: absolute;
                    left: 50%;
                    bottom: 0;
                    background-color: rgba($color: $black, $alpha: 0.1);
                    height: 1px;
                    width: 100%;
                }
                &::after{
                    content: '';
                    position: absolute;
                    right: 0;
                    top: 50%;
                    background-color: rgba($color: $black, $alpha: 0.1);
                    height: 100%;
                    width: 1px;
                }
            }
            .nav-til-link{
                display: flex;
                flex-direction: column;
                align-items: center;
                justify-content: center;
                text-align: center;
                cursor: pointer;
                &:hover{
                    .nav-til-icon{
                        color: $primary;
                    }
                    .nav-til-text{
                        color: $primary;
                    }
                }
                .nav-til-icon{
                    display: flex;
                    color: $dark100;
                    margin-bottom: 0.75rem;
                }
                .nav-til-text{
                    display: flex;
                    font-size: 1rem;
                    font-weight: 500;
                    color: $dark100;
                }
            }
        }   
    }
}
@media screen and (max-width: 767px) {
    .nav-text-icon-list-wrp{
        .nav-til-wrapper{
            .nav-til-item{
                padding: 1rem 0.5rem;
                .nav-til-link{
                    .nav-til-text{
                        display: flex;
                        font-size: 0.875rem;
                    }
                }
            }   
        }
    }   
}