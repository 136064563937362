.profile-wrapper{
    display: flex;
    flex-direction: column;
    width: 100%;
    background-color: $white;
    padding: 3.125rem 3.375rem;
    border-radius: 0.625rem;
}
.profile-info-wrp{
    .profile-info-img{
        margin-bottom: 2rem;
    }
    .profile-info-title{
        margin-bottom: 0;
        font-size: 1.375rem;
        font-weight: 500;
    }
    .profile-info-desc{
        font-size: 1rem;
        font-weight: 400;
        margin-bottom: 0.25rem;
    }
    .profile-info-desc-small{
        font-size: 0.875rem;
        font-weight: 500;
        color: #160546;
        margin-bottom: 0.25rem;
        opacity: 50%;

    }
    .profile-info-referralCode{
        font-size: 0.876rem;
        margin-left: 10px;
        margin-top: 10px;
    }
}

.profile-kyc-status {
    img {
        width: 22px;
        height: 22px;
    }
    p {
        margin-left: 5px;
    }
}
.depositlimits-wrp{
    display: flex;
    flex-direction: column;
    width: 100%;
    padding: 1.125rem 1.25rem;
    background-color: rgba($color: $dark100, $alpha: 0.08);
    border-radius: 6px;
    margin-bottom: 0.75rem;
    .depositlimits-first-line{
        color: $dark;
        margin-bottom: 1rem;
        .depositlimits-title{
            color: $dark;
            font-size: 1rem;
            font-weight: 700;
            margin-bottom: 0;
        }
        .btn.btn-link-primary {
            color: $dark;
        }
    }
}
.add-bank-button {
    height: 70px;
    border: 0;
    background-color: transparent;
}

@media screen and (max-width: 767px) {
    .profile-wrapper{
        padding: 2rem 1rem;
    }
    .profile-info-wrp{
        .profile-info-img{
            margin-bottom: 1rem;
        }
    }
    .depositlimits-wrp{
        padding: 1rem;
    }
}