.layout-wrapper {
    display: flex;
    flex-direction: column;
    width: 100%;
    position: relative;
    z-index: 3;
}

.layout-main-wrapper {
    min-height: 80vh;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}