.auth-wrapper{
    display: flex;
    flex-direction: row;
    width: 100%;
    .auth-left{
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        width: 18.75rem;
        background-color: $secondary100;
        position: relative;
        .auth-back-btn{
            position: absolute;
            left: 1.875rem;
            top: 1.875rem;
            color: $dark100;
        }
    }
    .auth-right{
        display: flex;
        flex-direction: column;
        width: calc(100% - 18.75rem);
        padding: 3rem;
        background-color: $white;
        .auth-title{
            font-size: 2.25rem;
            color: $dark100;
            font-weight: 500;
            margin-bottom: 0.75rem;
        }
    }
    .auth-kyc-content{
        display: flex;
        flex-direction: column;
        width: 100%;
        min-height: 20rem;
        align-items: center;
        justify-content: center;
    }
}
@media screen and (max-width: 767px) {
    .auth-wrapper{
        display: flex;
        flex-direction: column;
        .auth-left{
            width: 100%;
            justify-content: center;
            align-items: center;
            img{
                max-width: 50%;
            }
        }
        .auth-right{
            width: 100%;
            padding: 2rem;
            .auth-title{
                font-size: 2.25rem;
            }
        }
    }
}