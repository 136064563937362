.itd-card{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    text-align: center;
    padding: 2.25rem;
    .itd-top{
        display: flex;
        flex-direction: column;
    }
    .itd-bottom{
        display: flex;
        flex-direction: column;
        .btn{
            padding-left: 2.5rem;
            padding-right: 2.5rem;
        }
    }
    .itd-img{
        display: flex;
        flex-direction: column;
        align-items: center;
        margin-bottom: 2rem;
    }
    .itd-title{
        font-size: 1.875rem;
        font-weight: 500;
        color: $dark100;
        letter-spacing: -0.6px;
        margin-bottom: 1.5rem;
    }
    .itd-desc{
        font-size: 0.875rem;
        font-weight: 400;
        color: $dark;
        margin-bottom: 1.5rem;
        max-width: 72%;
        margin-left: auto;
        margin-right: auto;
    }
}