.view-breakup-card{
    display: flex;
    flex-direction: column;
    width: 100%;
    margin-top: 0.875rem;
    animation: fade 2s ease-in-out;
    opacity: 0;
    &.active{
        transform: translate(0, 0);
        opacity: 1;
    }
    @keyframes fade {
        0% { opacity: 0 }
        50% { opacity: 1 }
    }
    .view-breakup-main{
        display: flex;
        flex-direction: column;
        .vb-row{
            display: flex;
            align-items: center;
            justify-content: space-between;
            color: $dark100;
            margin-bottom: 0.375rem;
            &:last-child{
                margin-bottom: 0;
                .vb-row-text{
                    font-size: 0.9375rem;
                    font-weight: 700;
                }
                .vb-row-number{
                    font-size: 0.875rem;
                    font-weight: 700;
                }
            }
            .vb-row-text{
                font-size: 0.8125rem;
                font-weight: 400;
            }
            .vb-row-number{
                font-size: 0.75rem;
                font-weight: 400;
            }
        }
    }
}