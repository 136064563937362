.label-text-lists{
    .ltl-item{
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin-bottom: 0.625rem;
        .ltl-item-left{
            display: flex;
            align-items: center;
            font-size: 0.875rem;
            font-weight: 400;
            color: rgba($color: $dark, $alpha: 0.7);
            .ltl-item-label{
                display: flex;
                min-width: 7.5rem;
            }
            .ltl-item-sym{
            }
        }
        .ltl-item-text{
            font-size: 0.875rem;
            font-weight: 700;
            color: $dark;
            text-align: right;
            padding-left: 1.875rem;
            word-break: break-all;
        }
    }
}